* {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
::-webkit-scrollbar {
	display: none;
}

html,
body,
#root {
	height: 100%;
	width: 100%;
	display: flow-root;
	cursor: default;
}

#root {
	background-color: darkslategrey;
}

.splash {
	height: 100vh;
	width: 100%;
	position: absolute;
	z-index: 5;
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(20px);
	justify-content: center;
	align-items: center;
}

.progress {
	background-color: aliceblue;
	height: 6px;
	/* width: 40%; */
	border-radius: 8px;
}

.buttons {
	background-color: rgba(240, 248, 255, 0.3);
	border: none;
	font-size: large;
	border-radius: 15px;
	padding: 4px 12px;
	margin-bottom: 5%;
	color: aliceblue;
	transition: 0.5s;
}
.buttons:hover {
	background-color: rgba(133, 168, 167, 0.3);
	transition: 0.5s;
}

.App {
	height: 100%;
	display: flow-root;
}

.content {
	position: absolute;
	display: flex;
	z-index: 3;
	/* background-color: blue; */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	margin: 0% 10%;
	align-items: center;
	flex-direction: column;
}

.title {
	color: aliceblue;
}

.test {
	border: 2px solid aliceblue;
	backdrop-filter: blur(8px);
	padding-bottom: 1.8%;
	height: 100vh;
	width: 100%;
	display: flex;
	overflow-y: hidden;
}

#sidebar {
	border-radius: 12px;
	background-color: rgba(240, 248, 255, 0.3);
	display: flex;
	flex-direction: column;
	margin: 1% 0;
	padding: 5% 10px;
}

#sidebar nav {
	flex: 1;
}

#sidebar nav a.active span {
	color: inherit;
}

#sidebar ul {
	padding: 0;
	/* margin: 0; */
	list-style: none;
}

#sidebar nav a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* overflow: hidden; */

	white-space: pre;
	padding: 0.5rem;
	border-radius: 8px;
	text-decoration: none;
	gap: 1rem;
	color: aliceblue;
	transition: 0.3s;
}

#sidebar li {
	margin: 0.25rem 0;
	color: black;
}

#sidebar nav a:hover {
	background: rgba(133, 168, 167, 0.8);
	transition: 0.3s;
}

#sidebar nav a.active {
	/* background: hsl(224, 98%, 58%); */
	background: rgba(255, 170, 126, 0.7);
	color: aliceblue;
}

#sidebar nav a.pending {
	color: rgba(255, 170, 126, 0.7);
}

#content {
	display: flex;
	flex-direction: column;
	/* background-color: red; */
	height: 100%;
	width: 100%;
	margin: 1% 1% 1% 0;
	overflow-y: scroll;
}

#content .loading {
	opacity: 0.25;
	transition: opacity 200ms;
	transition-delay: 200ms;
}

.columnContainer {
	/* background-color: green; */
	height: fit-content;
	/* width: 15%; */
	display: "flex";
	flex-direction: "column";
	/* position: sticky; */
	/* padding: 0 1%; */
	margin-left: 1%;
	margin-top: 1%;
	margin-right: 1%;
}

.secondColumn {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: rgba(240, 248, 255, 0.3);
	margin-top: 15%;
	margin-bottom: 5%;
	border-radius: 12px;
	padding: 5% 10%;
	justify-content: center;
	align-items: center;
}

.secondColumn div a {
	display: flex;
	align-items: center;
	justify-content: space-between;

	white-space: pre;
	padding: 0.5rem;
	border-radius: 8px;
	text-decoration: none;
	gap: 1rem;
}
.icon {
	color: aliceblue;
	transition: 0.3s;
}

.icon:hover {
	/* color: rgba(133, 168, 167, 0.8); */
	color: rgba(255, 170, 126, 0.7);
	transition: 0.3s;
}

#zero-state {
	border-radius: 12px;
	background-color: rgba(240, 248, 255, 0.3);
	/* margin: auto auto; */
	padding: 1rem 2rem;
	text-align: center;
	color: aliceblue;
}

#indexContainer {
	/* background-color: blue; */
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

#error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: aliceblue;
}

.contentContainer {
	/* background-color: red; */
	display: flex;
	align-items: center;
	height: 60%;
}

.employment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10% 10% 0;
	background-color: rgba(240, 248, 255, 0.3);
	border-radius: 12px;
	padding: 0 5% 2%;
}

.square {
	/* background-color: blue; */
	/* margin: 10% 15%; */
	justify-content: center;
	align-items: center;
	flex: 1;
	display: flex;
	flex-direction: column;
}
#right {
	margin-right: 20%;
}
#left {
	margin-left: 20%;
}

.volume {
	display: none;
}
.contentContainerVertical {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20%;
}

.contentContainerVertical #left {
	margin: 0;
}

.contentContainerVertical #right {
	margin: 0;
	margin-top: 5%;
}

.verticalLine {
	background-color: aliceblue;
	width: 1px;
	height: 50%;
	border-radius: 12px;
}

#menu {
	display: none;
}

.menuBtn {
	align-self: flex-end;
	background: none;
	border: none;
	margin-top: 5%;
	margin-right: 5%;
}

/* ABOUT PAGE */
#aboutContainer {
	display: flex;
	/* justify-content: center; */
	align-items: center;
	margin-top: 5%;
}
.blurb {
	background-color: rgba(240, 248, 255, 0.3);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	color: aliceblue;
	/* justify-content: center; */
	height: fit-content;
	margin-left: 5%;
	padding: 2.5% 5%;
}

#me {
	border-radius: 12px;
	opacity: 0.8;
	/* height: 300px; */
	height: auto;
	max-width: 300px;
	margin: 0 0 0 10%;
}

/* ART PAGE */
#artContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* margin-top: 5%; */
	margin: 5% 15%;
}

.art {
	display: flex;
	align-items: center;
	margin-top: 3%;
}

/* EXPERIENCE PAGE */
.expContainer {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	/* background-color: red; */
	height: 100%;
	margin-top: 5%;
}

/* react tabs */
.react-tabs {
	-webkit-tap-highlight-color: transparent;
	
}

.react-tabs__tab-list {
	/* border-bottom: 1px solid #aaa; */
	display: flex;
	position: fixed;
	margin: 0 0 10px;
	padding: 0;
	left: 50%;
	margin-left: -20%;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 6px 12px;
	cursor: pointer;
	color: aliceblue;
	background: rgba(240, 248, 255, 0.3);
	transition: 0.3s;
}
.react-tabs__tab:hover {
	background-color: rgba(133, 168, 167, 0.8);
	transition: 0.3s;
}

.react-tabs__tab--selected {
	background: rgba(255, 170, 126, 0.7);
	color: aliceblue;
	border-radius: 5px 5px 0 0;
}
.react-tabs__tab--selected:hover {
	background: rgba(255, 170, 126, 0.7);
	color: aliceblue;
	border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	/* background: #fff; */
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}

@media (max-width: 1200px) {
	.employment {
		/* background-color: red; */
		margin-left: 10%;
		margin-right: 10%;
		text-align: center;
	}

	.square {
		/* background-color: blue; */
	}
	#left {
		margin-left: 10%;
		margin-right: 5%;
	}
	#right {
		margin-right: 10%;
		margin-left: 5%;
	}
}

@media (max-width: 1024px) {
	.square {
		/* background-color: green; */
	}
}

@media (max-width: 768px) {
}

@media (max-width: 640px) {
	.columnContainer {
		display: none;
	}

	#menu {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.navbar {
		/* justify-content: center; */
		top: 0;
		display: flex;
		margin-top: 20%;
		height: 80%;
		width: 100%;
		position: fixed;
		flex-direction: column;
		align-items: center;
		background-color: rgba(240, 248, 255, 0.3);
	}

	.link {
		color: aliceblue;
		font-size: 1.4rem;
		/* margin-bottom: 1.6rem; */
	}
	#content {
		margin: 0 5%;
		padding: 0;
	}
	.employment {
		margin: 0;
	}

	.contentContainerVertical {
		margin: 0;
		margin-top: 5%;
	}

	.test {
		border: 2px solid aliceblue;
		backdrop-filter: blur(8px);
		height: 90vh;
		margin-bottom: 5%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

@media (max-height: 670px) {
	.title {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.volume {
		display: block;
		margin-left: 10%;
		margin-top: 8px;
	}
}
