.playpause {
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    margin-top: 5%;
    margin-bottom: 5%;
    transition: 0.3s;
}
.playpause:hover {
    background-color: rgba(255,170,126, 0.8);
    transition: 0.3s;
}

.title {
    margin: 0;
    padding: 0;
    color: aliceblue;
}

.subtitle {
    margin: 0;
    padding: 0;
    color: aliceblue;
}
.container {
    /* margin-top: 5%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
}

/* @media (max-width: 640px) {
    .container {
		display: none;
		background-color: red;
	}
} */
@media (max-height: 670px) {
	.container {
		display: none;
	}
}